<template>
    <div class="o-filter">
        <div class="row  bg-info pt-5 pb-2">
            <div class="col-12 o-filter__col">
                <form ref="form" class="row pl-4 pr-4" @submit.stop.prevent="startFilter">
                    <div class="col-12 p-0">
                        <label for="search" class="text-primary font-weight-bold mb-1 o-filter__label">
                            Cerca contatto:
                        </label>
                        <b-form-input
                            id="search"
                            placeholder="Cerca"
                            type="text"
                            v-model="searchString.searchFilter"
                            class="mb-4 "
                        ></b-form-input>
                    </div>

                    <div class="col-12 p-0">
                        <b-form-group
                            label="Visualizza iscritti Newsletter:"
                            label-for="isNewsletter"
                            class="m-input w-100 mt-3"
                        >
                            <b-form-select
                                id="isNewsletter"
                                v-model="searchString.isNewsletter"
                                :options="isNewsletter"
                                value-field="value"
                                text-field="text"
                            >
                                <!--  <template #first>
                                   <b-form-select-option :value="0"></b-form-select-option>
                                 </template> -->
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12 p-0">
                        <b-form-group
                            label="Cerca corso:"
                            label-for="searchCourse"
                            class="m-input w-100 mt-3"
                        >
                            <b-form-select
                                id="searchCourse"
                                v-model="searchString.courseFilter"
                                :options="courses"
                                value-field="value"
                                text-field="name"
                            >
                                <template #first>
                                    <b-form-select-option :value="0"></b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div id="complexFilter " class="o-filterInfo" v-if="listFilter.length > 0">
                        <p class="text-primary font-weight-bold mb-1">Filtri</p>

                        <div
                            v-for="(item, index) in listFilter"
                            :key="item.index"
                            class="o-filterInfo__box"
                        >
                            <p v-if="item.bondType != null">
                                <strong>Tipo legame:</strong>
                                {{ item.bondType }}
                            </p>
                            <p>
                                <strong>Condizione:</strong>
                                {{ condizioneByTag(item.condition) }}
                            </p>
                            <p>
                                <strong>Tipologia:</strong>
                                {{ activityTypeNameById(item.idActivityType) }}
                            </p>
                            <p>
                                <strong>Attività:</strong>
                                {{ activityNameById(item.idActivity) }}
                            </p>
                            <p v-if="item.eventDt != null">
                                <strong>Data evento:</strong>
                                {{ chageFormatDate(item.eventDt) }}
                            </p>
                            <p v-if="item.startEventDt != null">
                                <strong>Data evento Inizio:</strong>
                                {{ chageFormatDate(item.startEventDt) }}
                            </p>
                            <p v-if="item.endEventDt != null">
                                <strong>Data evento Fine:</strong>
                                {{ chageFormatDate(item.endEventDt) }}
                            </p>
                            <p v-if="item.note != null">
                                <strong>Note:</strong>
                                {{ item.note }}
                            </p>


                            <p>
                                <input
                                    type="checkbox"
                                    @input="setCurrentSortActivityId(item.idActivity)"
                                    :checked="isSortActivity(item.idActivity)"
                                /> Ordina per questa attività
                            </p>

                            <div class="o-filterInfo__action">
                                <b-button
                                    variant="danger"
                                    size="sm"
                                    v-on:click="removeComplexFilterMethod(index)"
                                >Elimina
                                </b-button
                                >
                            </div>
                        </div>
                    </div>

                    <b-form class="w-100">
                        <b-button
                            v-b-toggle.collapse-addCondition
                            block
                            variant=""
                            class="o-filterInfo o-filterInfo__btn"
                        ><span></span>Aggiungi<span></span
                        ></b-button>
                        <b-collapse id="collapse-addCondition" visible class="mt-2">
                            <b-form-group
                                label="Tipo legame"
                                label-for="bondType"
                                class="m-input w-100"
                                v-if="listFilter.length > 0"
                            >
                                <b-form-select
                                    id="bondType"
                                    v-model="complexFilter.bondType"
                                    :options="bondTypeOptions"
                                >
                                </b-form-select>
                            </b-form-group>
                            <b-form-group
                                label="Condizione"
                                label-for="condizione"
                                class="m-input w-100"
                            >
                                <b-form-select
                                    id="condizione"
                                    v-model="complexFilter.condition"
                                    v-on:change="checkFilter"
                                    :options="conditionOptions"
                                >
                                </b-form-select>
                            </b-form-group>
                            <b-form-group
                                label="Tipologia Attività"
                                label-for="tipologiaAttivita"
                                class="m-input w-100 mt-3"
                            >
                                <b-form-select
                                    id="tipologiaAttivita"
                                    v-model="complexFilter.idActivityType"
                                    :options="tipologiaAttivita"
                                    v-on:change="checkFilter"
                                    @change="getListActivity"
                                    value-field="activity.id"
                                    text-field="activity.name"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                        ></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group
                                label="Attività"
                                label-for="attivita"
                                class="m-input w-100"
                            >
                                <b-form-select
                                    id="attivita"
                                    v-model="complexFilter.idActivity"
                                    v-on:change="checkFilter"
                                    :options="listActivity"
                                    value-field="activity.id"
                                    text-field="activity.name"
                                    :disabled="checkListActivity !== true"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                        ></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>

                            <v-date-picker class="" v-model="complexFilter.startEventDt">
                                <template v-slot="{ inputValue, togglePopover }">
                                    <b-form-group
                                        label="Data evento Inizio"
                                        label-for="input-eventDt "
                                        class="m-input "
                                    >
                                        <input
                                            id="input-eventDt "
                                            class="form-control"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        />
                                    </b-form-group>
                                </template>
                            </v-date-picker>

                            <v-date-picker class="" v-model="complexFilter.endEventDt"
                                           :min-date='complexFilter.startEventDt'>
                                <template v-slot="{ inputValue, togglePopover }">
                                    <b-form-group
                                        label="Data evento Fine"
                                        label-for="input-endEventDt"
                                        class="m-input "
                                    >
                                        <input
                                            id="input-endEventDt"
                                            class="form-control"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        />
                                    </b-form-group>
                                </template>
                            </v-date-picker>

                            <b-form-group label="Note" label-for="note" class="m-input w-100">
                                <b-form-input
                                    id="note"
                                    placeholder="Cerca"
                                    type="text"
                                    v-model="complexFilter.note"
                                    class="mb-4 "
                                ></b-form-input>
                            </b-form-group>

                            <b-button
                                v-if="complexFilter.condition !== ''"
                                v-on:click="onSubmit"
                                :disabled="isDisabled"
                                variant="success"
                                size="sm"
                                class="w-100"
                            >Aggiungi Condizione
                            </b-button
                            >
                        </b-collapse>
                    </b-form>
                    <span class="o-filterInfo__line mt-4 "></span>
                    <b-button block type="submit" variant="primary" class=" mt-4 w-100">
                        Applica filtri
                    </b-button>

                    <b-button block v-on:click="removeAllComplexFilterMethod()" variant="primary" class=" mt-4 w-100">
                        Rimuovi filtri
                    </b-button>
                </form>


            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "AppContactsFilter",
    props: [
        "searchImput",
        "tipologiaAttivita",
        "attivita",
        "complexFilterMethod",
        "removeComplexFilterMethod",
        "removeAllComplexFilterMethod",
        "startFilterMethod",
        "listFilter",
        "contactCurrentSortActivityId",
        "courses",
    ],

    data() {
        return {
            complexFilter: {
                bondType: "and",
                idActivityType: null,
                idActivity: null,
                condition: null,
                eventDt: null,
                startEventDt: null,
                endEventDt: null,
                note: null,
            },
            count: 0,
            searchString: {
                searchFilter: "",
                courseFilter: "",
                isNewsletter: "",
            },

            addBtn: false,

            isNewsletter: [
                {value: "", text: "Tutto"},
                {value: "true", text: "Includi"},
                {value: "false", text: "Escludi"},
            ],

            conditionOptions: [
                {value: "pos", text: "Includi"},
                {value: "neg", text: "Escludi"},
            ],

            bondTypeOptions: [
                {value: "and", text: "and"},
                {value: "or", text: "or"},
            ],

            listActivity: [],
        };
    },

    created() {
        this.syncFilter();
    },

    computed: {
        isDisabled: function () {
            return !this.addBtn;
        },

        checkListActivity() {
            if (this.listActivity.length == 0) {
                return false;
            } else {
                return true;
            }
        },
    },

    methods: {

        syncFilter() {
            // if (localStorage.getItem("contactCurrentSortActivityId")) {
                // this.currentSortActivityId = localStorage.getItem("contactCurrentSortActivityId");
                // console.log("inz"+this.currentSortActivityId)
            // }

            if (localStorage.getItem("searchText")) {
                this.searchString.searchFilter = localStorage.getItem("searchText");
            }
            if (localStorage.getItem("courseName")) {
                this.searchString.courseFilter = localStorage.getItem("courseName");
            }

            if (localStorage.getItem("isNewsletter")) {
                this.searchString.isNewsletter = localStorage.getItem("isNewsletter");
            }

            if (localStorage.getItem("filter")) {
                // this.listFilter = JSON.parse(localStorage.getItem("filter"));
                //trasformo il set diretto con un emit perchè il dato è nel componente genitore
                this.$emit("changeFilter", JSON.parse(localStorage.getItem("filter")) );
            }
        },

        setCurrentSortActivityId(activityId){
            // console.log("checked"+activityId);
            this.$emit("changeCurrentSortActivityId", activityId );
        },

        isSortActivity(activityId){
            return this.contactCurrentSortActivityId === activityId;
        },

      /*  async selectListActivity() {
        try {
          await this.$store.dispatch("activity/getActivity").then(() => {
            this.activity = this.$store.getters["activity/activity"];
            return this.activity;
          });
        } catch (e) {
          console.log({ message: "Some error getActivity" });
        }
      }, */

        checkFilter() {
            if (
                this.complexFilter.idActivityType != null &&
                this.complexFilter.idActivity != null &&
                this.complexFilter.condition != null
            ) {
                this.addBtn = true;
            }
        },

        async getListActivity() {
            try {
                await this.$store
                    .dispatch("activity/getActivitiesChildren", {
                        id: this.complexFilter.idActivityType,
                    })
                    .then(() => {
                        const getlistActivity = this.$store.getters[
                            "activity/activitiesChildren"
                            ];

                        this.listActivity = getlistActivity.payload.activities;
                        return this.listActivity;
                    });
            } catch (e) {
                console.log({message: "Some error getActivity"});
            }
        },

        startFilter(e) {
            e.preventDefault();

            localStorage.setItem("searchText", this.searchString.searchFilter);

            localStorage.setItem("courseName", this.searchString.courseFilter);

            localStorage.setItem("isNewsletter", this.searchString.isNewsletter);

            localStorage.setItem("filter", JSON.stringify(this.listFilter));

            this.startFilterMethod(this.searchString);
        },

        onSubmit(e) {
            e.preventDefault();
            // console.log(this.complexFilter);
            let editEventDt = null;
            let editStartEventDt = null;
            let editEndEventDt = null;

            if (this.complexFilter.eventDt !== null) {
                editEventDt = moment(String(this.complexFilter.eventDt)).format(
                    moment.HTML5_FMT.DATE
                );
            } else {
                editEventDt = null;
            }

            if (this.complexFilter.startEventDt !== null) {
                editStartEventDt = moment(String(this.complexFilter.startEventDt)).format(
                    moment.HTML5_FMT.DATE
                );
            } else {
                editStartEventDt = null;
            }


            if (this.complexFilter.endEventDt !== null) {
                editEndEventDt = moment(String(this.complexFilter.endEventDt)).format(
                    moment.HTML5_FMT.DATE
                );
            } else {
                editEndEventDt = null;
            }

            if (editStartEventDt != null && editEndEventDt === null) {
                editEndEventDt = editStartEventDt;
            }

            if (editStartEventDt === null && editEndEventDt != null) {
                editStartEventDt = editEndEventDt;
            }

            this.count = this.count + 1;
            const editComplexFilter = {
                count: this.count,
                idActivityType: this.complexFilter.idActivityType,
                idActivity: this.complexFilter.idActivity,
                bondType: this.complexFilter.bondType,
                condition: this.complexFilter.condition,
                eventDt: editEventDt,
                startEventDt: editStartEventDt,
                endEventDt: editEndEventDt,
                note: this.complexFilter.note,
            };
            // console.log(editComplexFilter);
            const sendcomplexFilter = {...editComplexFilter};
            this.complexFilterMethod(sendcomplexFilter);
            this.complexFilter = {
                idActivityType: null,
                idActivity: null,
                bondType: "and",
                condition: null,
                eventDt: null,
                startEventDt: null,
                endEventDt: null,
                note: null,
            };
        },

        activityNameById($id) {
            const activityName = this.$store.getters["activity/activityById"]($id)
                .activity.name;
            return activityName;
        },

        activityTypeNameById($id) {
            const activityTypeName = this.$store.getters[
                "typeActivity/typeActivityById"
                ]($id).activity.name;

            return activityTypeName;
        },

        condizioneByTag($tag) {
            let condizioneByTag = "";
            if ($tag == "pos") {
                condizioneByTag = "Includi";
            } else {
                condizioneByTag = "Escludi";
            }

            return condizioneByTag;
        },

        chageFormatDate($date) {
            /*  let text = "";
            if ($date !== null) {
              text = moment.utc(new Date($date), "YYYY-MM-DD").format("DD/MM/YYYY");
            } else {
              text = "";
            } */
            return moment.utc(new Date($date), "YYYY-MM-DD").format("DD/MM/YYYY");
        },
  },
};
</script>
